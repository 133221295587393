import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios';
import EventBus from './EventBus'
import $ from 'jquery';
require('jquery-serializejson');
import MentoringFunctionality from "./components/mentoring/MentoringFunctionality";
import CustomFilters from "./filters";
// require('./filters');

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


if ($("#app").length) {
  new Vue({
    el: "#app",
    delimiters: ["[[", "]]"],
    components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
   },
   data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      pusherKey: window.pusherKey,
      pusherCluster: window.pusherCluster,
      showError: false,
      showSuccess: false,
    }
  },
  methods: {
    openProfileMobileTab() {
      EventBus.$emit('OpenProfileMobileTab');
    }
},
mounted() {
    let form = document.querySelector(`[data-id="${window.formAnchor}"]`);
    if (form) {
        form.addEventListener('submit', event => {
                console.log("Sending via ajax");

              var form = event.target;
              var data = new FormData(form);

              var method = form.getAttribute("method");
              var action = form.getAttribute("action");

              var request = new XMLHttpRequest();
              request.open(method, action ? action : window.location.href, true);
              request.setRequestHeader("Cache-Control", "no-cache");

              // Set the Craft specific AJAX headers
              request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
              request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

              request.onload = () => {
                if (request.status === 200) {
                  var response = JSON.parse(request.response);

                  if (response.success && response.finished) {
                    form.reset();
                    this.showSuccess = true;
                  } else if (response.errors || response.formErrors) {
                    console.log("Field Errors", response.errors);
                    console.log("Form Errors", response.formErrors);

                    this.showError = true;
                  }

                  // Update the Honeypot field if using JS enhancement
                  if (response.honeypot) {
                    var honeypotInput = form.querySelector("input[name^=freeform_form_handle_]");
                    if (honeypotInput) {
                        honeypotInput.setAttribute("name", response.honeypot.name);
                        honeypotInput.setAttribute("id", response.honeypot.name);
                        honeypotInput.value = response.honeypot.hash;
                    }
                  }
                } else {
                  console.error(request);
                }
              };

              request.send(data);
              event.preventDefault();
          }, false);
        console.log("Added event listener in Vue");
    }
}
  });
}

// $( ".purchasableId" ).change(function(e) {
//   $(".purchasableInfo").hide();
//   $("#"+($(this).find(":selected").data('info'))).toggle();
// });


var elem = document.getElementById('element');

lottie.loadAnimation({
  container: elem, // the dom element that will contain the animation
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: 'data.json' // the path to the animation json
});
