<template>
<div class="flex-grow flex flex-col overflow-auto">
  <div class="bg-gray-200 flex-grow py-4 px-3 overflow-auto" ref="messages" @scroll="updateScrollIndicator">
    <div class="loading pt-4 pb-4" v-if="loadingMessages && (messages.length === 0) && !reachedFirstMessage"></div>
    <div v-for="message, i in allMessages">

      <div v-if="(i === 0 || outputDateLabel(message.time) !== outputDateLabel(allMessages[i-1].time)) && message.time !== null" class="text-center text-xs text-gray-500 w-full border-b border-gray-300 mt-8 -mb-2 pb-2">
        {{ outputDateLabel(message.time) }}
      </div>

      <div v-if="recipient === message.author" class="flex items-center mt-12">

        <div class="order-last w-2/12 md:w-3/12 lg:w-1/3 pl-4 sm:pl-6 md:pl-8 text-sm">
          <span v-if="message.time !== null">{{ time(message.time) }}</span>
        </div>

        <div class="bg-white px-2 py-2 md:py-2 md:px-3 w-10/12 md:w-9/12 lg:w-2/3 rounded-lg rounded-bl-none leading-normal text-sm relative">
          <div class="absolute top-0 left-0 -mt-6 ml-2 md:ml-6 text-gray-500  text-xs">{{ message.authorName }}</div>
          <div :inner-html.prop="message.message | messageToHtml"></div>
        </div>

      </div>

      <div v-else class="flex items-center mt-12">

        <div class="w-2/12 md:w-3/12 lg:w-1/3 pr-2 sm:pr-6 md:pr-5 text-xs text-right text-gray-500">
          <span v-if="message.time !== null">{{ time(message.time) }}</span>
        </div>

        <div class="bg-blue-500 px-2 py-2 md:py-2 md:px-3 w-10/12 md:w-9/12 lg:w-2/3 rounded-xl rounded-br-none leading-normal text-sm  relative">
          <div class="absolute top-0 left-0 -mt-6 ml-2 md:ml-3 text-gray-500 text-xs">{{ message.authorName }}</div>
          <div class="text-white" :inner-html.prop="message.message | messageToHtml"></div>
        </div>

      </div>

    </div>
    <div class="text-sm text-center text-gray-500 mt-4" v-if="(messages.length === 0) && reachedFirstMessage">
      - this is the beginning of your chat -
    </div>
  </div>
  <div class="flex p-3 flex-shrink-0 overflow-auto">
    <resizable-textarea>
      <textarea name="message" class="outline-none resize-none rounded-l-xl flex-1 bg-gray-200 px-3 py-2 leading-normal text-sm" maxlength="2000" rows="1" v-model="message" @keydown.enter="function(e){ if (!e.shiftKey) {sendMessage(); return false;} }"
        ref="textarea"></textarea>
    </resizable-textarea>
    <button type="submit" class="flex-shrink-0 bg-gray-200 hover:bg-gray-300 transition-all duration-300 text-white rounded-tr-xl rounded-br-xl px-2 text-sm" @click.prevent="sendMessage">
      <svg class="" fill="#4299e1" viewBox="0 0 32 32" width="40" height="30" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z" />
        </g>
      </svg>
      <span class="hidden ">Send</span>
    </button>
  </div>
</div>
</template>

<script>
import EventBus from '../../../EventBus';

export default {
  name: "MentoringChatBox",
  props: ['messages', 'recipient', 'user-id', 'loading-messages', 'reached-first-message'],
  data() {
    return {
      message: null,
      isScrolledToBottom: true,
      isScrolledToTop: false,
      tempMessage: null,
      tempDate: ''
    }
  },
  components: {
    'resizable-textarea': {
      methods: {
        resizeTextarea(event) {
          event.target.style.height = 'auto'
          event.target.style.height = (event.target.scrollHeight) + 'px'
        },
      },
      mounted() {
        this.$nextTick(() => {
          this.$el.setAttribute('style', 'height:' + (this.$el.scrollHeight) + 'px;overflow-y:hidden;')
        })

        this.$el.addEventListener('input', this.resizeTextarea)
      },
      beforeDestroy() {
        this.$el.removeEventListener('input', this.resizeTextarea)
      },
      render() {
        return this.$slots.default[0]
      },
    }
  },
  beforeDestroy() {
    this.$root.$off('MentoringSendMessage');
  },
  methods: {
    sendMessage() {
      const message = this.message;
      this.message = null;

      // Show temp message before real one gets sent to db
      this.tempMessage = {
        'author': this.userId,
        'authorName': "me",
        'message': message,
        'read': 1,
        'time': null,
      };

      EventBus.$emit('MentoringSendMessage', {
        'message': message,
        'recipient': this.recipient,
        [this.$root.csrfName]: this.$root.csrfToken
      });
      EventBus.$emit('MentoringTempChatMessageAdded', {}); // use this to temporarily block the message fetching
      this.messageboxScrollPosition();
      this.$nextTick((_this) => this.messageboxScrollPosition());


      // Reset textarea height
      this.$refs.textarea.style.height = 'auto';
    },
    time(timestamp) {
      const pad = function(num) {
        return ("0" + num).slice(-2);
      }

      var date = new Date(timestamp * 1000);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      return pad(hours) + ":" + pad(minutes);
    },
    updateScrollIndicator() {
      this.isScrolledToBottom = this.$refs.messages.scrollHeight - this.$refs.messages.clientHeight <= this.$refs.messages.scrollTop + 10;
      this.isScrolledToTop = this.$refs.messages.scrollTop <= 10;
      EventBus.$emit('MentoringScrolledToTop', {
        'is': this.isScrolledToTop
      });
    },
    messageboxScrollPosition() {
      if (this.isScrolledToBottom) {
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight - this.$refs.messages.clientHeight;
      }
    },
    timestampToDate(timestamp) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dateObject = new Date(timestamp * 1000);
      return days[dateObject.getDay()] + ' ' + (dateObject.getMonth() + 1) + '/' + (dateObject.getDate()) + '/' + (dateObject.getFullYear());
    },
    // checkIfDateLabelIsChanged(timestamp) {
    //   return this.timestampToDate(timestamp) !== this.tempDate;
    // },
    outputDateLabel(timestamp) {
      const newDate = this.timestampToDate(timestamp);
      // this.tempDate = newDate;
      return newDate;
    }
  },
  computed: {
    allMessages() {
      let passMessages = this.messages.slice().reverse();

      if (this.tempMessage !== null) {
        passMessages.push(this.tempMessage);
      }
      return passMessages;
    },
  },
  watch: {
    messages() {
      this.tempMessage = null;
      this.tempDate = '';
    },
    allMessages() {
      this.messageboxScrollPosition();
    }
  }
}
</script>

<style scoped>
textarea {
  max-height: 90px;
}
</style>
