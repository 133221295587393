<template>
  <div class="flex flex-col p-4">

    <h2 class="font-bold py-3">My Profile</h2>

    <div class="mt-4">
      <div class="overflow-hidden rounded-full inline-block">
        <img :src="profile.photo ? profile.photo.medium : '/assets/static/profile.png'" :alt="profile.fullName" width="160" height="160">
      </div>
    </div>

    <h3 class="mt-4 mb-2 font-bold">Bio</h3>

    <div class="bg-gray-200 rounded-lg md:py-4 p-3 md:px-6">
      <div v-if="!editBio">
        <div class="text-xs lg:text-xs paragraph-text " :inner-html.prop="profile.bio"></div>
        <div class="mt-2 text-right">
          <a href="#" @click.prevent="editBio = !editBio" class="w-64 px-3 rounded border-gray-700 border bg-gray-300 transition-all duration-300 hover:bg-gray-400 uppercase text-xs">Edit</a>
        </div>
      </div>
      <div v-else>

        <editor-menu-bar :editor="bioEditor" v-slot="{ commands, isActive }">
          <div class="mb-1">
            <button
                class="font-bold text-xs rounded w-6 h-6 items-center justify-center border border-gray-500 inline-flex mr-1"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
                title="Bold"
            >B</button>
            <button
                class="italic text-xs rounded w-6 h-6 items-center justify-center border border-gray-500 inline-flex mr-1"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
                title="Italic"
            >I</button>
            <button
                class="underline text-xs rounded w-6 h-6 items-center justify-center border border-gray-500 inline-flex mr-1"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
                title="Underline"
            >U</button>
          </div>
        </editor-menu-bar>
        <editor-content class="text-sm xl:text-base paragraph-text" :editor="bioEditor" />
        <div class="mt-2 text-right">
          <a href="#" @click.prevent="editBio = !editBio" class="w-64 px-3 rounded border-gray-700 border bg-gray-300 transition-all duration-300 hover:bg-gray-400 uppercase text-xs">Cancel</a>
          <a href="#" @click.prevent="saveBio" class="w-64 px-3 rounded border-brand-500 border bg-brand-500 transition-all duration-300 hover:border-gray-700 uppercase text-xs text-white ml-1">Save</a>
        </div>
      </div>
    </div>

    <h3 class="mt-4 mb-2 font-bold">Email</h3>

    <div v-if="!editEmail">
      <div class="bg-gray-200 rounded-lg md:py-4 p-3 md:px- flex flex-wrap justify-between">
        <div :inner-html.prop="profile.email"></div>
        <div class="ml-3">
          <a href="#" @click.prevent="editEmail = !editEmail" class="w-64 px-3 rounded border-gray-700 border bg-gray-300 transition-all duration-300 hover:bg-gray-400 uppercase text-xs">Edit</a>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="bg-gray-200 rounded-lg py-4 px-6">
        <div><input type="email" v-model="profile.email" ref="editEmailField" placeholder="Email Address"/></div>
        <div class="mt-2"><input type="password" ref="editEmailFieldPassword" placeholder="Enter Password"/></div>
        <div class="mt-3 text-right">
          <a href="#" @click.prevent="editEmail = !editEmail" class="w-64 px-3 rounded border-gray-700 border bg-gray-300 transition-all duration-300 hover:bg-gray-400 uppercase text-xs">Cancel</a>
          <a href="#" @click.prevent="saveEmail" class="w-64 px-3 rounded border-brand-500 border bg-brand-500 transition-all duration-300 hover:border-gray-700 uppercase text-xs text-white ml-1">Save</a>
        </div>
        <div class="mt-3">
          <div class="text-red-500" v-if="emailErrors">
            <ul v-for="errors in emailErrors">
              <li v-for="error in errors">
                {{error}}
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

  <h3 class="mt-8 mb-2 font-bold">WWVP Expiry</h3>
  <div class="bg-gray-200 text-gray-500 font-bold rounded-lg py-4 px-6 w-24">
    <span >03/22</span>
  </div>


  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  // Blockquote,
  // CodeBlock,
  // HardBreak,
  // Heading,
  // HorizontalRule,
  // OrderedList,
  // BulletList,
  // ListItem,
  // TodoItem,
  // TodoList,
  Bold,
  // Code,
  Italic,
  // Link,
  // Strike,
  Underline,
  // History,
} from 'tiptap-extensions'
import axios from "axios";

export default {
  name: "MentoringProfile",
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: ['profile'],
  data() {
    return {
      editBio: false,
      bioEditor: null,
      editEmail: false,
      editorExtensions: [],
      emailErrors: {}
    }
  },
  mounted() {
    this.extensions = [
      new Bold(),
      new Italic(),
      new Underline(),
    ];
  },
  methods: {
    saveBio() {
      const _this = this;
      axios.post('/actions/mentoring-module/mentoring/set-user-bio', { [this.$root.csrfName] : this.$root.csrfToken, bio: this.bioEditor.getHTML() })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
        });
      this.editBio = !this.editBio;
      this.profile.bio = this.bioEditor.getHTML();
    },
    saveEmail() {
      const _this = this;
      _this.emailErrors = {};
      axios.post('/actions/users/save-user', {
        [this.$root.csrfName]: this.$root.csrfToken,
        email: this.$refs.editEmailField.value,
        userId: this.profile.id,
        password: this.$refs.editEmailFieldPassword.value
      })
        .then(function (response) {
          console.log('success: ', response);
          if (typeof response.data.errors !== 'undefined') {
            _this.emailErrors = response.data.errors;
          } else {
            _this.editEmail = !_this.editEmail;
            _this.emailErrors = {};
          }
        })
        .catch(function (error) {
          console.log('error: ', error);
          _this.emailErrors = error;
        })
        .then(function () {
        });
      // this.profile.email = this.$refs.editEmailField.value;
    }
  },
  watch: {
    editBio(val) {
      if (val) {
        this.bioEditor = new Editor({
          content: this.profile.bio,
          extensions: this.extensions
        })
      } else {
        this.bioEditor.destroy()
      }
    },
    editEmail(val) {
      if (val = true) {
        // this.$refs.editEmailField.value = this.profile.email;
      }
    }
  }
}
</script>

<style scoped>
</style>
